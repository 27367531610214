<template>
  <div id="app" class="app-root" :class="{ grayed: isGrayed, blacked: isBlacked }">
    <img class="svg-sprite" id="svg-sprite" src="@/assets/images/sprite.svg" alt="svg-sprite" />

    <div class="page-loader" v-if="isLoading">
      <img class="page-loader-icon" src="@/assets/images/loader.gif" alt="Loader" />
    </div>

    <div class="page-wrapper">
      <router-view v-if="hasLoaded" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex"
import VueCookies from "vue-cookies"
import { ROUTE_NAMES } from "@/defaults"

export default {
  data() {
    return {
      hasLoaded: false,
      completelyLoaded: false
    }
  },
  computed: {
    ...mapState(["isLoading", "isEmbedded", "_id", "profileFacts", "token"]),

    isGrayed() {
      return this.$route.meta.isGrayed
    },
    isBlacked() {
      return this.$route.meta.isBlacked
    }
  },
  async beforeMount() {
    await this.propagateGetValues()
    await this.$store.dispatch("getProfile")
    await this.$store.dispatch("plan/getValues")

    window.addEventListener("message", this.receiveMessage, false)
    this.hasLoaded = true
  },
  beforeDestroy() {
    window.removeEventListener("message", this.receiveMessage)
  },
  methods: {
    ...mapMutations([
      "setStravaAuthorized",
      "setToken",
      "setAction",
      "setEmbedded",
      "setFtp",
      "setUserIdForDebugging",
      "setLocale",
      "setShowPreferencesModal"
    ]),
    ...mapActions("history", ["getHistory"]),
    ...mapActions(["upsertProfileByToken"]),

    receiveMessage(message) {
      const { type } = message.data
      if (type === "go-to-history-page") {
        const path = "/history"
        if (this.$route.path !== path) this.$router.push({ name: "history" })
      }
      if (type === "go-to-dcs-page") {
        const path = "/"
        if (this.$route.path !== path) this.$router.push({ name: "dcs" })
      }
      if (type === "create-plan") {
        this.$router.push({ name: ROUTE_NAMES.WELCOME_DCS })
      }
      if (type === "show-preferences") {
        this.setShowPreferencesModal(true)
      }
    },

    async propagateGetValues() {
      const uri = window.location.search
      const params = new URLSearchParams(uri)

      const tokenFromCookies = VueCookies.get("token")
      const action = params.get("action")
      const ftp = params.get("ftp")
      const stravaAuthorized = params.get("stravaAuthorized")
      const token = params.get("token") || tokenFromCookies
      const embedded = params.get("embedded")
      const lang = params.get("lang")
      const userId = params.get("userId")

      if (action) {
        this.setAction(action)
      }
      if (stravaAuthorized) {
        this.setStravaAuthorized(Boolean(Number(stravaAuthorized)))
      }

      if (embedded) {
        this.setEmbedded(Boolean(Number(embedded)))
      }
      if (ftp) {
        this.setFtp(Number(ftp))
      }
      if (userId) {
        this.setUserIdForDebugging(Number(userId))
      }
      if (lang) {
        this.setLocale(lang)
      }
      if (token) {
        this.setToken(token || null)
        await this.upsertProfileByToken()
      }
    }
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/theme.scss";

.app-root {
  min-height: 100%;
  display: flex;
  flex-direction: column;

  &.grayed {
    background-color: #f4f5f9;
  }
  &.blacked {
    background-color: $black;
  }

  &.is-modal-open {
    overflow: hidden;
  }
}

.logo {
  display: inline-block;
  text-align: left;
  padding: 0 16px;

  img {
    display: block;
  }

  @include media("<tablet") {
    text-align: center;
  }
}

.page {
  &-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 6;
    background-color: rgba(255, 255, 255, 0.6);

    &-icon {
      height: 60px;
      width: 180px;
    }
  }

  &-wrapper {
    padding: 0;
  }
}
</style>
